<template>
  <div>
    <!-- <label>
      AutoComplete
      <GmapAutocomplete @place_changed="setPlace" 
      :options="options"
          >
      </GmapAutocomplete>
      <button @click="usePlace">Add</button>
    </label>
    <br/>

    <GmapMap style="width: 100%; height: 300px;" :zoom="12" :center="center">
      <GmapMarker v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        />
      <GmapMarker
        v-if="place"
        label="★"
        :position="{
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        }"
        />
    </GmapMap> -->
      <div>
    <label>
      AutoComplete
      <GmapAutocomplete @place_changed="setPlace" 
      :options="options"
          >
      </GmapAutocomplete>
      <button @click="usePlace">Add</button>
    </label>
    <br/>

    <GmapMap style="width: 100%; height: 300px;" :zoom="12" :center="{lat: 16.051833080342913, lng: 108.2071136436135}">
      <GmapMarker v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        />
      <GmapMarker
        v-if="place"
        label="★"
        :position="latlng"
        />
    </GmapMap>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      markers: [],
      place: null,
      latlng: {},
      description: 'Autocomplete',
      center: {lat: 16.051833080342913, lng: 108.2071136436135},
      defaultBounds: { 
          north: 16.5,
        south: 15.5,
        east: 108.5,
        west: 107.5
        },
    options: {
            bounds: this.defaultBounds,
            strictBounds: true,
            componentRestrictions: { country: "vi" },
            fields: ["address_components", "name"],
          },
    }
  },
  methods: {
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      this.place = place
    },
    usePlace(place) {
      if (this.place) {
        this.latlng = {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          };
        this.markers.push({
          position: this.latlng
        })
        this.place = null;
      }
    }
  }
  // data() {
  //   return {
  //     markers: [],
  //     place: null,
  //     latlng: {},
  //     description: 'Autocomplete addresss',
  //     center: {lat: 16.051833080342913, lng: 108.2071136436135},
  //     defaultBounds: { 
  //         north: 17,
  //       south: 15,
  //       east: 109.2,
  //       west: 107
  //       },
  //   options: {
  //           bounds: this.defaultBounds,
  //           strictBounds: true,
  //           componentRestrictions: { country: "vi" },
  //           fields: ["address_components", "name"],
  //         },
  //   }
  // },
  // methods: {
  //   setDescription(description) {
  //     this.description = description;
  //   },
  //   setPlace(place) {
  //     this.place = place
  //   },
  //   usePlace(place) {
  //     if (this.place) {
  //       this.latlng = {
  //           lat: this.place.geometry.location.lat(),
  //           lng: this.place.geometry.location.lng(),
  //         };
  //       this.markers.push({
  //         position: this.latlng
  //       })
  //       this.center = this.latlng
  //       this.place = null;
  //     }
  //   }
  // }
}
</script>

<!-- Remember to change the center of the map  -->
