<template>
  <LargeLayout>
    <div class="col-12">
      <h1 class="text-center text-success">
        {{ $t("property_create.Create Property") }}
      </h1>
      <div class="d-flex justify-content-between my-3">
        <b-button
          v-b-modal.modal-1
          class="text-white bg-success border-0 px-3 py-2 border-radius-sm"
          >{{ $t("property_create.New Property") }}</b-button
        >
        <b-pagination
          v-if="properties.length > 0"
          v-model="page"
          :total-rows="count"
          :per-page="size"
          @change="handlePageChange"
          aria-controls="history-list"
          align="center"
        ></b-pagination>
      </div>

      <b-modal
        id="modal-1"
        :title="$t('property_create.Create New Property')"
        :hide-footer="true"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        size="xl"
      >
        <CreateProperty
          @create-success="createSuccess"
          @closeModal="closeModal"
        />
      </b-modal>

      <div v-for="property in properties" :key="property._id">
        <Card
          :property="property"
          :id="property._id"
          :title="property.title"
          :image="property.images[0]"
          :plan="property.plan"
          :address="property.address"
          :cost="property.cost"
          :rent_in_vnd="property.rent_in_vnd"
          :utility_cost="property.utility_costs"
          :wifi_cost="property.wifi_cost"
          :area="property.square"
          :bedroom="property.bedroom"
          :bathroom="property.bathroom"
          :neighborhood="property.condition[0] + ',' + property.condition[1]"
          :route_name="$route.name"
          @deleteProperty="deleteProperty"
          @updateProperty="updateProperty"
          @hideProperty="hideProperty"
        />
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import CreateProperty from "./components/Create-Property.vue";
import Card from "../../components/utils/Card.vue";
import axiosIns from "../../libs/axiosConfig";
export default {
  components: { LargeLayout, Card, CreateProperty },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      properties: [],
      size: 6,
      page: 1,
      count: 0,
      message: {
        created: {
          type: "success",
          title: this.$t("toast.Success"),
          text: this.$t("toast.Created_property"),
        },
        deleted: {
          type: "success",
          title: this.$t("toast.Success"),
          text: this.$t("toast.Deleted"),
        },
        edited: {
          type: "success",
          title: this.$t("toast.Success"),
          text: this.$t("toast.Edited"),
        },
        error: {
          type: "danger",
          title: this.$t("toast.Error"),
          text: this.$t("toast.Something went wrong"),
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-1");
    },
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    handlePageChange(value) {
      this.page = value;
      axiosIns
        .get("/property?page=" + this.page + "&size=" + this.size)
        .then((response) => {
          this.properties = response.data.properties;
          this.count = response.data.totalItems;
        });
    },
    deleteProperty(id) {
      axiosIns
        .delete("/property/" + id)
        .then((response) => {
          const index = this.properties.findIndex((item) => item._id === id);
          this.properties.splice(index, 1);
          this.makeToast("deleted");
        })
        .catch((err) => {
          console.log(err.response);
          this.makeToast("error");
        });
    },
    updateProperty(data) {
      const index = this.properties.findIndex((item) => item._id === data._id);
      this.properties.splice(index, 1, data);
      this.makeToast("edited");
    },
    createSuccess(data) {
      this.properties.unshift(data);
      this.makeToast("created");
    },
    hideProperty(id) {
      axiosIns
        .post("/property/hide/" + id)
        .then((response) => {
          this.makeToast("edited");
        })
        .catch((err) => {
          console.log(err.response);
          this.makeToast("error");
        });
    },
  },
  created() {
    axiosIns
      .get("/property?page=" + this.page + "&size=" + this.size)
      .then((response) => {
        this.properties = response.data.properties;
        this.count = response.data.totalItems;
      });
  },
};
</script>

<style></style>
